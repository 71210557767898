
import React, { Component } from 'react';
import { Link as RouterLink, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { signoutUser } from '../apis';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import HomeIcon from '@material-ui/icons/Home';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  logo: {
    [theme.breakpoints.up('sm')]: {
      height: 64,
    },
    height: 44,
    margin: '10px 0',
  },
  documentation: {
    fontSize: '1rem',
  },
});

class Header extends Component {
  
  handleLogout = () => {
    const { dispatch } = this.props;
    dispatch(signoutUser());
  };

  render() {
    const { classes, isAuthenticated, signoutRequest } = this.props;
    let subtitle = 'Trial';
    if (process.env.REACT_APP_ENV !== 'prod') {
     subtitle += ` (${process.env.REACT_APP_ENV})`;
    }
    return (
      <AppBar color="default">
        <Toolbar variant='dense'>
          <Link href="https://scaledynamics.io/warpjs">
            <img src='./warpjs_white_logo.svg' alt='WarpJS' className={classes.logo} />
          </Link>
          <Typography component='h1' variant="h5" noWrap>
            {subtitle}
          </Typography>
          <div className={classes.grow} />
          <IconButton color="default"
            href="https://warpjs.dev"
            rel="noopener" target="_blank" title="Read documentation" alt="Documentation">
            <MenuBookIcon color="secondary" />
          </IconButton>
          <Route path="/:page(signup|forgot|auth)/" render={ 
            () =>
              <Button variant="outlined" color="default" component={RouterLink} to="/signin" >
                Sign&nbsp;in
              </Button>
            } />
          <Route exact path="/terms" render={ 
            () =>
            <IconButton color="default" component={RouterLink} to="/" alt="Go back to home">
              <HomeIcon color="secondary" />
            </IconButton>
            } />
          <Button 
            variant="outlined"
            color="default"
            onClick={this.handleLogout} 
            disabled={signoutRequest}
            style={ isAuthenticated ? {} : {display:'none'} }>
              Log&nbsp;out
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.api.isAuthenticated,
    signoutRequest: state.api.signoutRequest,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Header));
