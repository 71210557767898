import { 
  NOTIFICATION_MESSAGE,
  CHECK_USER_SUCCESS,
  CHECK_USER_FAILURE,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PWD_REQUEST,
  FORGOT_PWD_SUCCESS,
  FORGOT_PWD_ERROR,
  UPDATE_PWD_REQUEST,
  UPDATE_PWD_SUCCESS,
  UPDATE_PWD_ERROR,
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNOUT_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_ERROR,
  CREATE_PROJECTS_REQUEST,
  CREATE_PROJECTS_SUCCESS,
  CREATE_PROJECTS_ERROR,
  DELETE_PROJECTS_REQUEST,
  DELETE_PROJECTS_SUCCESS,
  DELETE_PROJECTS_ERROR,
} from '../actions';

export default (
  state = {
    notification: null,
    isAuthenticated: false,
    user: null,
    projects: [],
    error: null,
    forgotPwdRequest: false,
    acceptTermsRequest: false,
    signinRequest: false,
    signupRequest: false,
    deleteUserRequest: false,
    getUserRequest: false,
    setInvitationRequest: false,
    setInvitationSuccess: false,
    createProjectRequest: false,
    verifyEmailRequest: false,
  },
  action
) => {
  switch(action.type) {
    /** NOTIFICATION_MESSAGE */
    case NOTIFICATION_MESSAGE:
      return {
        ...state,
        notification: action.notification,
      };
    /** CHECK USER SESSION */
    case CHECK_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case CHECK_USER_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
      };
    /** SIGNIN USER */
    case SIGNIN_REQUEST:
      return {
        ...state,
        signinRequest: true,
        error: null,
      };
    case SIGNIN_SUCCESS: 
      return {
        ...state,
        signinRequest: false,
        isAuthenticated: true,
        error: null,
      };
    case SIGNIN_ERROR: 
      return {
        ...state,
        error: action.error,
        signinRequest: false,
        isAuthenticated: false,
      };
    /** SIGNUP USER */
    case SIGNUP_REQUEST:
      return {
        ...state,
        signupRequest: true,
        error: null,
      };
    case SIGNUP_SUCCESS: 
      return {
        ...state,
        signupRequest: false,
        error: null,
      };
    case SIGNUP_ERROR: 
      return {
        ...state,
        error: action.error,
        signupRequest: false,
      };
    /** FORGOT PASSWORD */
    case FORGOT_PWD_REQUEST:
      return {
        ...state,
        forgotPwdRequest: true,
        error: null,
      };
    case FORGOT_PWD_SUCCESS:
      return {
        ...state,
        forgotPwdRequest: false,
      };
    case FORGOT_PWD_ERROR:
      return {
        ...state,
        forgotPwdRequest: false,
        error: action.error,
      };
    /** UPDATE PASSWORD */
    case UPDATE_PWD_REQUEST:
      return {
        ...state,
        resetPwdRequest: true,
        resetPwdSuccess: false,
        error: null,
      };
    case UPDATE_PWD_SUCCESS:
      return {
        ...state,
        resetPwdRequest: false,
        resetPwdSuccess: true,
      };
    case UPDATE_PWD_ERROR:
      return {
        ...state,
        resetPwdRequest: false,
        resetPwdSuccess: false,
        error: action.error,
      };
    /** VERIFY EMAIL */
    case VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifyEmailRequest: true,
        error: null,
      };
    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmailRequest: false,
      };
    case VERIFY_EMAIL_ERROR:
      return {
        ...state,
        verifyEmailRequest: false,
        error: action.error,
      };
    /** SIGNOUT USER */
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    /** DELETE USER */
    case DELETE_USER_REQUEST:
      return {
        ...state,
        deleteUserRequest: true,
        error: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserRequest: false,
        isAuthenticated: false,
        user: null,
        error: null,
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserRequest: false,
        error: action.error,
      };
    /** ACCEPT TERMS */
    case ACCEPT_TERMS_REQUEST:
      return {
        ...state,
        acceptTermsRequest: true,
        error: null,
      };
    case ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        acceptTermsRequest: false,
        error: null,
      };
    case ACCEPT_TERMS_ERROR:
      return {
        ...state,
        acceptTermsRequest: false,
        error: action.error,
      };
    /** GET USER */
    case GET_USER_REQUEST:
      return {
        ...state,
        getUserRequest: true,
        error: null,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        getUserRequest: false,
        user: action.user,
        projects: action.user.projects,
        error: null,
      };
    case GET_USER_ERROR:
      return {
        ...state,
        getUserRequest: false,
        error: action.error,
        user: null,
        projects: null,
      };
     /** CREATE PROJECT API */
     case CREATE_PROJECTS_REQUEST:
      return {
        ...state,
        createProjectRequest: true,
        error: null,
      };
    case CREATE_PROJECTS_SUCCESS:
      if (action.project) {
        state.projects = [
          ...state.projects,
          action.project,
        ];
      }
      return {
        ...state,
        createProjectRequest: false,
        error: null,
      };
    case CREATE_PROJECTS_ERROR:
      return {
        ...state,
        createProjectRequest: false,
        error: action.error,
      };
    /** DELETE PROJECT API */
    case DELETE_PROJECTS_REQUEST:
      return {
        ...state,
        deleteProjectRequest: true,
        error: null,
      };
    case DELETE_PROJECTS_SUCCESS:
      if (action.project) {
        for (let i = 0; i < state.projects.length; i++) {
          if (state.projects[i].name === action.project.name) {
            state.projects = [...state.projects.slice(0, i), ...state.projects.slice(i + 1)];
            break;
          }
        }
      }
      return {
        ...state,
        deleteProjectRequest: false,
        projects: state.projects,
        error: null,
      };
    case DELETE_PROJECTS_ERROR:
      return {
        ...state,
        deleteProjectRequest: false,
        error: action.error,
      };
    default: 
      return state;
  }
};

