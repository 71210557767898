import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { checkInvitationCode, signupUser } from '../apis';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import { StyledPaper, StyledAvatar } from '../components/StyledComponents';

class Signup extends Component {
  
  state = { 
    formData: {
      email: '',
      password: '',
      invitationCode: '',
    }, 
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const { formData } = this.state;
    const code = this.props.match.params.code || localStorage.getItem('invitationCode') || '';
    if (code) {
      localStorage.setItem('invitationCode', code);
      formData.invitationCode = code;
      this.setState({ formData });
      // check invitation code
      dispatch(checkInvitationCode(code));
    }
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { formData } = this.state;
    dispatch(signupUser(formData.email, formData.password, formData.invitationCode, () => {
      this.props.history.push('/');
    }));
  };

  render() {
    const { signupRequest, isAuthenticated } = this.props;
    const { formData } = this.state;

    if (isAuthenticated) {
      return <Redirect to='/' />;
    } else {
      return (
        <Container component='main' maxWidth='xs'>
          <StyledPaper>
            <StyledAvatar>
              <AccountCircleOutlinedIcon />
            </StyledAvatar>
            <Typography component='h1' variant='h5'>
              Sign up
            </Typography>
            <ValidatorForm onSubmit={this.handleSubmit} >
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                autoFocus
                required
                autoComplete="username"
                label="Email Address"
                name="email"
                type="email"
                validators={['required', 'isEmail']}
                errorMessages={['Email is required', 'Please enter a correct email address.']}
                onChange={this.handleChange}
                value={formData.email}
              />
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                required
                autoComplete="new-password"
                label="Password"
                name="password"
                type="password"
                validators={['required', 'minStringLength:6']}
                errorMessages={['Password is required', 'Password must be at least 6 characters.']}
                onChange={this.handleChange}
                value={formData.password}
              />
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                required
                label="Invitation Code"
                name="invitationCode"
                validators={['required']}
                errorMessages={['this field is required']}
                onChange={this.handleChange}
                value={formData.invitationCode}
              />
              <FormHelperText>
                if you do not have invitation code, please&nbsp;
                <Link href="https://scaledynamics.io/warpjs" rel="noopener noreferrer" target="_blank">
                  contact us
                </Link>
              </FormHelperText>
              <Box mt={5}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  disabled={signupRequest}
                >
                  Sign up
                </Button>
              </Box>
            </ValidatorForm>
          </StyledPaper>
        </Container>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.api.isAuthenticated,
    signupRequest: state.api.signupRequest,
  };
}

export default connect(mapStateToProps)(Signup);