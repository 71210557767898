import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { resetPassword, verifyEmail } from '../apis';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';

import { StyledPaper, StyledAvatar } from '../components/StyledComponents';

const styles = () => ({
  form: {
    width: '100%',
  },
});

class Firebase extends Component {
  state = { 
    formData: {
      newPassword: '',
    }, 
  };
  
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmitPassword = () => {
    const { dispatch, location } = this.props;
    const { formData } = this.state;
    const params = queryString.parse(location.search);
    dispatch(resetPassword(formData.newPassword, params.oobCode, () => {
      this.props.history.push('/signin');
    }));
  };

  render() {
    const { 
      classes, dispatch, location, resetPwdRequest,
    } = this.props;
    const { formData } = this.state;
    const params = queryString.parse(location.search);
      switch(params.mode) {
        case 'resetPassword':
          return (
            <Container component='main' maxWidth='xs'>
              <StyledPaper>
                <StyledAvatar>
                  <AccountCircleOutlinedIcon />
                </StyledAvatar>
                <Typography component='h1' variant='h5'>
                  Reset password
                </Typography>
                <ValidatorForm className={classes.form} onSubmit={this.handleSubmitPassword} >
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    autoComplete="new-password"
                    label="New password"
                    name="newPassword"
                    type="password"
                    validators={['required', 'minStringLength:6']}
                    errorMessages={['Password is required', 'Password must be at least 6 characters.']}
                    onChange={this.handleChange}
                    value={formData.newPassword}
                  />
                  <Box mt={3}>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      disabled={resetPwdRequest}
                    >
                      Reset
                    </Button>
                  </Box>
                </ValidatorForm>
              </StyledPaper>
            </Container>
          );
          case 'verifyEmail':
            dispatch(verifyEmail(params.oobCode, () => {
              this.props.history.push('/signin');
            }));
            return (<CircularProgress color="secondary" />);
        default:
          return <Redirect to='/' />;
      }
  }
}

function mapStateToProps(state) {
  return {
    resetPwdRequest: state.api.resetPwdRequest,
    resetPwdSuccess: state.api.resetPwdSuccess,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Firebase));