import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CookieConsent from 'react-cookie-consent';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Header from './components/Header';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import Notification from './components/Notification';

import Signin from './pages/Signin';
import Signup from './pages/Signup';
import Forgot from './pages/Forgot';
import Firebase from './pages/Firebase';
import Account from './pages/Account';
import Terms from './pages/Terms';

const theme = createMuiTheme({
  palette: {
    default: {
      main: '#000',
      dark: '#333',
    },
    primary: {
      main: '#014066',
      dark: '#33607B',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#ae341a',
    },
    success: {
      main: '#014066',
    },
    warning: {
      main: '#b26123',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontWeight: 400,
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        padding: 0,
      },
      colorDefault: {
        backgroundColor: process.env.REACT_APP_ENV !== 'prod' ? '#CF731C' : '#000',
        color: '#fff',
      },
    },
    MuiPaper: {
      root: {
        color: '#333',
        backgroundColor: '#f2f2f2',
        padding: 20,
      },
    },
    MuiButton: {
      outlined: {
        color: '#fff',
        borderColor: '#fff',
      },
    },
    MuiCircularProgress: {
      indeterminate: {
        marginTop: '140px',
        margin: '0 auto',
      },
    },
    MuiDialog: {
      paper: {
        padding: 0,
        margin: 16,
      },
    },
  },
});

function App(props) {
  const { isAuthenticated } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Notification />
      <Header />
      <Switch>
        <Route exact path='/terms' component={Terms}/>
        <ProtectedRoute
          exact
          path='/'
          component={Account}
          isAuthenticated={isAuthenticated}
        />
        <Route exact path='/auth' component={Firebase}/>
        <Route exact path='/signin' component={Signin}/>
        <Route exact path='/signup' component={Signup}/>
        <Route path='/signup/:code' component={Signup}/>
        <Route path='/forgot' component={Forgot}/>
        <Redirect from='*' to='/' />
      </Switch>
      <Footer />
      <CookieConsent
        location='bottom'
        buttonText='I agree'
        cookieName='starbaseCookieConsent'
        style={{ background: '#191919' }}
        buttonStyle={{ color: '#333', background: '#fff', fontSize: '14px', borderRadius: '4px' }}
      >
        <Typography component='p'>
          By continuing your visit to this site without changing the settings of your cookies, you accept the use of cookies allowing us to make business statistics
        </Typography>
    </CookieConsent>
    </MuiThemeProvider>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.api.isAuthenticated,
  };
}

export default connect(mapStateToProps)(App);