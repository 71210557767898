import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import queryString from 'query-string';

import { acceptTerms } from '../apis';

const styles = () => ({
  content: {
    width: '100%',
    minHeight: 'calc(100vh - 400px)',
    overflowY: 'scroll',
    border: '1px solid #eee',
    borderRadius: 8,
  },
});

class Terms extends Component {

  acceptTerms = () => {
    const { dispatch } = this.props;
    dispatch(acceptTerms(() => {
      this.props.history.push('/');
    }));
  }

  render() {
    const { classes, location, acceptTermsRequest } = this.props;
    const params = queryString.parse(location.search);
  
    return (
      <Grid container component="main" justify="center">
        <Grid item xs={12} sm={6}>
          <Paper>
            <Typography variant="h4">
              End-User Licence Agreement
            </Typography>
            <Box my={2} >
              <iframe title="eula" className={classes.content} src="./eula/eula_v1.html"></iframe>
            </Box>
            { (params.accept &&
              <Button
                fullWidth
                variant='contained'
                color='primary'
                onClick={this.acceptTerms}
                disabled={acceptTermsRequest}
              >
                Accept and continue
              </Button>
            ) }
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    acceptTermsRequest: state.api.acceptTermsRequest,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Terms));

