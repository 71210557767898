import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { forgotPassword } from '../apis';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { StyledPaper, StyledAvatar } from '../components/StyledComponents';

const styles = () => ({
  form: {
    width: '100%',
  },
});
class Forgot extends Component {
  state = { 
    formData: {
      email: '',
    }, 
  };
  
  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { formData } = this.state;
    dispatch(forgotPassword(formData.email, () => {
      this.props.history.push('/signin');
    }));
  };

  render() {
    const { classes, forgotPwdRequest, isAuthenticated } = this.props;
    const { formData } = this.state;
    if (isAuthenticated) {
      return <Redirect to='/' />;
    } else {
      return (
        <Container component='main' maxWidth='xs'>
          <StyledPaper>
            <StyledAvatar>
              <AccountCircleOutlinedIcon />
            </StyledAvatar>
            <Typography component='h1' variant='h5'>
              Forgot password
            </Typography>
            <ValidatorForm className={classes.form} onSubmit={this.handleSubmit} >
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                required
                autoComplete="username"
                autoFocus
                label="Email Address"
                name="email"
                type="email"
                validators={['required', 'isEmail']}
                errorMessages={['Email is required', 'Please enter a correct email address.']}
                onChange={this.handleChange}
                value={formData.email}
              />
              <Box mt={3}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  disabled={forgotPwdRequest}
                >
                  Send email
                </Button>
              </Box>
            </ValidatorForm>
          </StyledPaper>
        </Container>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.api.isAuthenticated,
    forgotPwdRequest: state.api.forgotPwdRequest,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Forgot));