import { request, getToken, setToken, removeToken } from './fetch';
import { 
  setNotification,
  checkUserSuccess, checkUserFailure,
  signupRequest, signupSuccess, signupError,
  signinRequest, signinSuccess, signinError, 
  signoutSuccess,
  forgotPwdRequest, forgotPwdSuccess, forgotPwdError,
  verifyEmailRequest, verifyEmailSuccess, verifyEmailError,
  updatePwdRequest, updatePwdSuccess, updatePwdError,
  acceptTermsRequest, acceptTermsSuccess, acceptTermsError,
  getUserRequest, getUserSuccess, getUserError,
  deleteUserRequest, deleteUserSuccess, deleteUserError,
  createProjectsRequest, createProjectsSuccess, createProjectsError,
  deleteProjectRequest, deleteProjectSuccess, deleteProjectError,
} from '../actions/api';

const ApiError = (callback, error) => dispatch => {
  switch(error.code) {
    case 'token_not_found':
      dispatch(signoutUser());
      break;
    default:
      dispatch(callback(error));
      break;
  }
};

export const checkUser = () => dispatch => {
  if (getToken()) {
    dispatch(checkUserSuccess());
  } else {
    dispatch(checkUserFailure());
  }
};

export const checkInvitationCode = (invitationCode) => dispatch => {
  request(`/invitations/${invitationCode}`, {})
    .catch(error => {
      if (error && error.code === 'invitation_code_not_found'){
        // redirect to landing page
        window.location.replace(process.env.REACT_APP_LANDING_PAGE_URL);
      }
    });
};

export const signupUser = (email, password, invitationCode, callback) => dispatch => {
  dispatch(signupRequest());
  request(`/invitations/${invitationCode}`, {
  }, dispatch)
    .then(() => {
      return request('/register', {
        method: 'POST',
        body: { 
          method: 'password',
          invitationCode,
          login: email,
          password,
        },
      });
    })
    .then(() => {
      dispatch(setNotification({
        code: 'account-created',
        type: 'success',
      }));
      dispatch(signupSuccess());
      callback();
    })
    .catch(error => {
      dispatch(signupError(error));
    });
};

export const signinUser = (email, password) => dispatch => {
  dispatch(signinRequest());
  request('/get-token', {
    method: 'POST',
    body: { 
      method: 'password',
      login: email,
      password,
    },
  })
    .then((response) => {
      setToken(response.token);
      dispatch(signinSuccess());
    })
    .catch(error => {
      dispatch(signinError(error));
      if (error && error.code === 'user_not_found'){
        // redirect to landing page
        window.location.replace(process.env.REACT_APP_LANDING_PAGE_URL);
      }
    });
};

export const signoutUser = () => dispatch => {
  removeToken();
  dispatch(signoutSuccess());
};

export const forgotPassword = (email, callback) => dispatch => {
  dispatch(forgotPwdRequest());
  request('/forgot-password', {
    method: 'POST',
    body: { 
      method: 'password',
      login: email,
    },
  })
  .then(() => {
    dispatch(setNotification({
      code: 'forgot-pwd',
      type: 'success',
    }));
    dispatch(forgotPwdSuccess());
    callback();
  })
  .catch(error => {
    dispatch(forgotPwdError(error));
  });
};

export const resetPassword = (password, code, callback) => dispatch => {
  dispatch(updatePwdRequest());
  request('/reset-password', {
    method: 'POST',
    body: { 
      method: 'password',
      password,
      login: code,
    },
  })
    .then(() => {
      dispatch(setNotification({
        code: 'reset-pwd',
        type: 'success',
      }));
      dispatch(updatePwdSuccess());
      callback();
    })
    .catch(error => {
      dispatch(updatePwdError(error));
    });
};

export const verifyEmail = (code, callback) => dispatch => {
  dispatch(verifyEmailRequest());
  request('/confirm-login', {
    method: 'POST',
    body: { 
      method: 'password',
      login: code,
    },
  })
    .then(() => {
      dispatch(setNotification({
        code: 'verify-email',
        type: 'success',
      }));
      dispatch(verifyEmailSuccess());
      callback();
    })
    .catch(error => {
      dispatch(verifyEmailError(error));
      callback();
    });
};

export const acceptTerms = (callback) => dispatch => {
  dispatch(acceptTermsRequest());
  request('/accept-terms', {
    method: 'POST',
  })
    .then((user) => {
      dispatch(acceptTermsSuccess(user));
      callback();
    })
    .catch(error => {
      dispatch(ApiError(acceptTermsError, error));
    });
};

export const getUser = () => dispatch => {
  dispatch(getUserRequest());
  request('/user?projects=true')
    .then(user => {
      dispatch(getUserSuccess(user));
    })
    .catch(error => {
      dispatch(ApiError(getUserError, error));
    });
};

export const deleteUser = (email, password) => async dispatch => {
  dispatch(deleteUserRequest());
  request('/unregister', {
    method: 'POST',
    body: { 
      method: 'password',
      login: email,
      password,
    },
  })
    .then(() => {
      dispatch(setNotification({
        code: 'delete-account',
        type: 'success',
      }));
      dispatch(deleteUserSuccess());
      dispatch(signoutUser());
    })
    .catch(error => {
      dispatch(deleteUserError(error));
    });
};

export const createProject = (name) => async dispatch => {
  dispatch(createProjectsRequest());
  request('/projects', {
    method: 'POST',
    body: { name },
  })
  .then((data) => {
    dispatch(createProjectsSuccess(data));
  })
  .catch(error => {
    dispatch(ApiError(createProjectsError, error));
  });
};

export const deleteProject = (project) => async dispatch => {
  dispatch(deleteProjectRequest());
  return request('/projects/' + project.name, {
    method: 'DELETE',
  })
  .then(() => {
    dispatch(deleteProjectSuccess(project));
  })
  .catch(error => {
    dispatch(ApiError(deleteProjectError, error));
  });
};