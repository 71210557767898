import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

const styles = (theme) => ({
  paper: { 
    position: 'relative',
    height: '100%',
  },
  actionButton: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  title: {
    borderBottom: '1px solid #000',
  },
});

class GettingStarted extends Component {

  state = { 
    open: false,
  }

  handleClose = () => {
    this.setState({ 
      open: false,
    });
  }

  openLimitationDialog = () => {
    this.setState({ open: true });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} md={6}>
        <Paper className={classes.paper}>
          <Typography component='h2' variant="h4" className={classes.title}>
          Welcome to Starbase
          </Typography>
          <Box my={2}>
            <Typography component="p">
                Here you can declare your projects in order to build and deploy it in a serverless space.
            </Typography>
          </Box>
          <Box mt={2} mb={5}>
            <Button
              color='primary'
              onClick={this.openLimitationDialog}
              startIcon={<ReportProblemOutlinedIcon />}
            >
              WarpJS Trial supported platforms
            </Button>
          </Box>
          <Button
            className={classes.actionButton}
            variant='outlined'
            color='primary'
            href="https://warpjs.dev"
            rel="noopener" target="_blank"
          >
            Getting Started Guide
          </Button>
        </Paper>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="WarpJS Trial supported platforms">
          <DialogTitle>WarpJS Trial supported platforms</DialogTitle>
            <DialogContent>
              <strong>Deployment:</strong>
              <ul>
                <li><strong>Provider</strong>: GCP</li>
                <li><strong>Location</strong>: us-central1</li>
                <li><strong>Runtime</strong>: Node.js 8.15.0</li>
                <li><strong>Ram</strong>: 1024MB</li>
                <li><strong>Timeout</strong>: 30s</li>
              </ul>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" variant="outlined" autoFocus>
                Ok
              </Button>
            </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles)(GettingStarted);