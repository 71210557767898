import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import GettingStarted from '../components/GettingStarted';
import UserDetails from '../components/UserDetails';
import TableProjects from '../components/TableProjects';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getUser } from '../apis';

class Account extends Component {
  
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getUser());
  }
  
  render() {
    const { apiError, user } = this.props;
    if (apiError && apiError.code === 'eula_not_accepted') {
      return <Redirect to="/terms?accept=true" />;
    } else {
      if (user) {
        return (
          <Container component="main" maxWidth="lg">
            <Grid container spacing={3}>
              <GettingStarted />
              <UserDetails />
              <TableProjects />
            </Grid>
          </Container>
        );
      } else {
        return (<CircularProgress color="secondary" />);
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.api.user,
    apiError: state.api.error,
  };
}

export default connect(mapStateToProps)(Account);

