import Cookies from 'js-cookie';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const cookieSecure = (process.env.REACT_APP_COOKIE_SECURE === 'true');
let authToken = null;

export const setToken = (value) => {
  authToken = value;

  Cookies.set('sb-auth', value, { 
    path: '/',
    expires: 30, // 30 days
    secure: cookieSecure,
    sameSite: 'Strict',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  });
};

export const getToken = () => {
  if (!authToken) {
    authToken = Cookies.get('sb-auth');
  }
  return authToken;
};

export const removeToken = () => {
  authToken = null;
  Cookies.remove('sb-auth', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
};


export const request = async (url, options) => {
  const token = getToken();
  // Create headers.
  options = options || {};
  options.headers = new Headers({
    'Content-Type': 'application/json',
  });
  if (token) {
    options.headers.append('Token', token);
  }
  if (options.body) {
    options.body = JSON.stringify(options.body);
  }
  return fetch(`${BASE_URL}${url}`, options)
    .then(res => res.json())
    .then(res => {
      if (res.error) {
        throw (res.error);
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      if (err.code) {
        throw err;
      }
      // return generic error
      throw new Error('Internal error. Check your network and retry!');
    });
};