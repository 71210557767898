export default {
  // Api
  'user_not_found': 'No user found! Check your data and retry.',
  'disposable_email': 'We have detected you entered a disposable email address. Please fill-in your actual email address, we won\'t spam you.',
  'invitation_code_not_found': 'Please enter a valid invitation code to create your account.',
  'not_confirmed_email': {
    message: 'We just sent a confirmation link to your email address. Please check your spams as well, just in case...',
    type: 'success',
  },
  'eula_not_accepted': {
    message: 'Please read the follow terms before getting aboard.',
    type: 'success',
  },

  // Success
  'forgot-pwd': 'An email has been sent! Please click on link inside it to change password.',
  'reset-pwd': 'Your password has been updated. Please sign in with your new credentials.',
  'verify-email': 'Your account has been confirmed, you can sign in.',
  'account-created': 'Account created. An email has been sent! Please click on link inside it to activate your account.',
  'delete-account': 'Your account has been deleted. Thank you!',
};