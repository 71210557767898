
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import GitHubIcon from '@material-ui/icons/GitHub';

const styles = (theme) => ({
  footer: {
    padding: theme.spacing(1, 1),
    marginTop: 'auto',
    backgroundColor: '#191919',
    color: '#fff',
  },
  logo: {
    width: 150,
    lineHeight: 0,
    marginBottom: 4,
  },
  copyright: {
    fontSize: '10px',
    display: 'block',
  },
  footerMargin: {
    margin: '4px 0 4px 8px',
  },
  footerButton: {
    backgroundColor: '#444',
    color: '#fff',
  },
});

class Footer extends Component {

  render() {
    const { classes } = this.props;
    return (
      <footer className={classes.footer}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" justify="center">
            <Grid item md={2}>
              <Typography align="center" component="div" className={classes.logo}>
                <Link href="https://scaledynamics.io/warpjs">
                  <img src='./warpjs_white_logo.svg' alt='WarpJS' height="30" />
                </Link>
                <Typography className={classes.copyright}>
                  {'© '}
                  <Link color="inherit" href="https://scaledynamics.io" rel="noopener noreferrer" target="_blank">
                    ScaleDynamics
                  </Link>{' '}
                  {new Date().getFullYear()}
                </Typography>
              </Typography>
            </Grid>
            <Grid item md={5}>
              <Grid container justify="center">
                <Grid item>
                  <Box m={1}>
                    <Typography variant="body2" align="center">
                      <Link color="inherit" href="https://scaledynamics.io/privacy-policy">
                        Privacy Policy
                      </Link>{' | '}
                      <Link color="inherit" href="https://scaledynamics.io/legal-info">
                        Legal Information
                      </Link>{' | '}
                      <Link color="inherit" component={RouterLink} to="/terms">
                        End-User Licence Agreement
                      </Link>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <Grid container justify="flex-end">
                <Grid item>
                  <Typography variant="body2" align="center">
                    <Button 
                      size="small" variant="contained" color="default"
                      className={`${classes.footerButton} ${classes.footerMargin}`} 
                      startIcon={<GitHubIcon />} 
                      href="https://github.com/WarpJS/demos"
                      rel="noopener noreferrer" target="_blank">
                        Code samples
                    </Button>
                    <Button 
                      size="small" variant="contained" color="secondary"
                      className={classes.footerMargin} 
                      href="https://github.com/WarpJS/beta/issues"
                      rel="noopener noreferrer" target="_blank">
                        Report an issue
                      </Button>
                    <Button 
                      size="small" variant="contained" color="default"
                      className={`${classes.footerButton} ${classes.footerMargin}`}  
                      href="mailto:feedback@warpjs.com"
                      >
                        feedback@warpjs.com
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);
