import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import labels from '../apis/labels';


const styles = theme => ({
  close: {
    position: 'absolute',
    right: 0,
    padding: theme.spacing(0.5),
    top: '50%',
    transform: 'translateY(-50%)',
  },
  message: {
    padding: '8px 12px 8px 4px',
  },
  errorNotification: {
    backgroundColor: theme.palette.error.main,
  },
  successNotification: {
    backgroundColor: theme.palette.success.main,
  },
  warningNotification: {
    backgroundColor: theme.palette.warning.main,
  },
});

class Notification extends Component {
  state = {
    open: false,
    message: null,
  };

  handleClose = () => {
    this.setState({ open : false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { apiError, notification } = this.props;
    if ((apiError && !prevProps.apiError) 
      || (apiError && prevProps.apiError && prevProps.apiError.code !== apiError.code)) {
      let message = apiError.message;
      let type = 'error';
      let i18n = labels[apiError.code];
      if (i18n) {
        if (typeof i18n === 'object') {
          message = i18n.message || apiError.message;
          type = i18n.type || 'error';
        } else {
          message = i18n;
        }
      }
      this.setState({
        message: {
          value: message,
          key: apiError.code,
          type,
        },
        open: true,
      });
      return false;
    }
    if ((notification && !prevProps.notification)
      || (notification && prevProps.notification !== notification)) {
      this.setState({
        message: {
          value: labels[notification.code],
          key: notification.code,
          type: notification.type,
        },
        open: true,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;
    const { message } = this.state;
    let type = 'notification';
    if (message && message.type) {
      type = message.type + 'Notification';
    }
    return (
      <Snackbar 
        ContentProps={{
          classes: {
              root: classes[type],
              message: classes.message,
          },
        }}
        key={this.state.message ? this.state.message.key : undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={8000}
        onClose={this.handleClose}
        open={this.state.open}
        message={this.state.message ? this.state.message.value : undefined}
        action={
          <IconButton
            aria-label='close'
            color='inherit'
            className={classes.close}
            onClick={this.handleClose}>
            <CloseIcon />
          </IconButton>
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    apiError: state.api.error,
    notification: state.api.notification,
  };
}

export default withStyles(styles)(connect(mapStateToProps)(Notification));