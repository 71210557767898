import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Popover from '@material-ui/core/Popover';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Link from '@material-ui/core/Link';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

import { createProject, deleteProject } from '../apis';

const styles = () => ({
  title: {
    borderBottom: '1px solid #000',
  },
  tableContainer: {
    overflowX: 'auto',
  },
  th: {
    minWidth: 100,
  },
  thAction: {
    width: 44,
  },
  copyButton: {
    padding: 4,
    marginLeft: 4,
  },
  infoIcon: {
    fontSize: 14,
    cursor: 'pointer',
    padding: 0,
    marginBottom: 16,
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function truncate(input) {
  if (input.length > 40) {
    return input.substring(0, 20) + '...' + input.substring(input.length-20, input.length);
  } else {
    return input;
  }
}

class TableProjects extends Component {
  
  state = { 
    createProjectOpen: false,
    deleteProjectOpen: false,
    openSupportId: false,
    anchorSupportId: null,
    openTooltip: false, 
    anchorTooltip: null,
    projectToDelete: null,
    supportId: '',
    formData: {
      name: '',
    },
    order: 'desc',
    orderBy: 'creationDate',
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleClose = () => {
    this.setState({ 
      createProjectOpen: false, 
      deleteProjectOpen: false,
      openSupportId: false, 
      anchorSupportId: null,
      openTooltip: false, 
      anchorTooltip: null,
    });
  }

  formatDate = (str) => {
    const date = str.split('T');
    return date[0];
  }

  formatUrl = (url) => {
    const { classes } = this.props;
    if (url) {
      
      const endUrl = url + '/index.html';
      return (
      <Fragment>
        <Link href={endUrl} rel="noopener noreferrer" target="_blank" title={endUrl}>
          {truncate(endUrl)}
        </Link>
        <CopyToClipboard text={endUrl}>
        <IconButton className={classes.copyButton} aria-label="Copy webpage url" title="Copy webpage url">
          <FileCopyOutlinedIcon />
        </IconButton>
      </CopyToClipboard>
    </Fragment>);
    }
    return <Typography variant="body2" component="span">not deployed yet
    </Typography>;
  }

  openCreateProjectDialog = () => {
    this.setState({ createProjectOpen: true, formData: { name: '' }});
  }

  createProject = () => {
    const { dispatch } = this.props;
    const { formData } = this.state;
    dispatch(createProject(formData.name));
    this.handleClose();
  }

  openDeleteProjectDialog = (project) => {
    this.setState({ deleteProjectOpen: true, projectToDelete: project });
  }

  deleteProject = () => {
    const { dispatch } = this.props;
    const { projectToDelete } = this.state;
    if (projectToDelete) {
      dispatch(deleteProject(projectToDelete));
    }
    this.handleClose();
  }

  openPopOver = (project, event) => {
    this.setState({ 
      openSupportId: true, 
      supportId: project.supportId,
      anchorSupportId: event.currentTarget,
    });
  }

  openPopOverTooltip = (event) => {
    this.setState({ 
      openTooltip: true, 
      anchorTooltip: event.currentTarget,
    });
  }

  createSortHandler = (property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ 
      orderBy: property,
      order: isAsc ? 'desc' : 'asc',
    });
  }

  render() {
    const { classes, projects } = this.props;
    const { formData, order, orderBy } = this.state;
    const sortedProjects = stableSort(projects, getComparator(order, orderBy));

    return (
      <Fragment>
        <Grid item xs={12}>
          <Paper>
            <Typography component='h1' variant="h4" className={classes.title}>
              Projects
            </Typography>
            <div className={classes.tableContainer}>
              <Table aria-label="project table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.th}>
                      <TableSortLabel
                        active={true}
                        direction={orderBy === 'creationDate' ? order : 'asc'}
                        onClick={() => this.createSortHandler('creationDate')}
                      >
                        creation Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.th}>
                      <TableSortLabel
                          active={true}
                          direction={orderBy === 'name' ? order : 'asc'}
                          onClick={() => this.createSortHandler('name')}
                        >
                          name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.th}>Deployment URL</TableCell>
                    <TableCell className={classes.th}>
                      ID&nbsp;
                      <IconButton 
                        color="primary" 
                        className={classes.infoIcon} 
                        aria-label="Please use this ID when posting public issues" 
                        component="span"
                        onClick={this.openPopOverTooltip.bind(this)}>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell className={classes.thAction}>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedProjects.map(row => (
                    <TableRow key={row.name}>
                      <TableCell>{this.formatDate(row.creationDate)}</TableCell>
                      <TableCell component="th" scope="row">
                        <strong>{row.name}</strong>
                      </TableCell>
                      <TableCell>{this.formatUrl(row.url)}</TableCell>
                      <TableCell>
                      <Button variant="outlined" color="primary" onClick={this.openPopOver.bind(this, row)}>
                        Show
                      </Button>
                      </TableCell>
                      <TableCell>
                        <IconButton color="primary" aria-label="Delete project" component="span" title={'Delete project ' + row.name} onClick={() => this.openDeleteProjectDialog(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <Box mt={3}>
              <Button
                color="primary"
                size="large"
                startIcon={<AddCircleIcon />}
                onClick={this.openCreateProjectDialog}
              >
                Create a new project
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Dialog
          open={this.state.createProjectOpen}
          onClose={this.handleClose}
          maxWidth="sm"
          fullWidth
          aria-labelledby="create new project"
        >
          <DialogTitle>Create project</DialogTitle>
          <ValidatorForm onSubmit={this.createProject} > 
            <DialogContent>
              <DialogContentText >
                The project name is a code name you will be asked for in your webpack configuration plugin. Has to be unique for your account.
              </DialogContentText>
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                required
                autoFocus
                label="Name"
                name="name"
                validators={['required', 'maxStringLength:214', 'matchRegexp:^[a-z0-9-._]*$']}
                errorMessages={[
                  'Project name is required', 
                  'Project name must be less than or equal to 214 characters.',
                  'Project name must only contains [a-z], [0-9], underscore (_), dash (-), dot (.)',
                  ]}
                onChange={this.handleChange}
                value={formData.name}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="default">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="outlined">
                Create
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        <Dialog
          open={this.state.deleteProjectOpen}
          onClose={this.handleClose}
          aria-labelledby="Are you sure you want to delete this project?">
          <DialogTitle>Are you sure you want to delete this project?</DialogTitle>
          <DialogContent>
            <DialogContentText >
              Project and related deployments will be detroyed. Do you wish to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="default">
              Cancel
            </Button>
            <Button onClick={this.deleteProject} color="primary" variant="outlined" autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Popover
          open={this.state.openSupportId}
          anchorEl={this.state.anchorSupportId}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component="span" className={classes.breakWord}>
            {this.state.supportId}
            <CopyToClipboard text={this.state.supportId}>
              <IconButton className={classes.copyButton} aria-label="Copy support ID to clipboard" title="Copy support ID to clipboard">
                <FileCopyOutlinedIcon />
              </IconButton>
            </CopyToClipboard>
          </Typography>
        </Popover>
        <Popover
          open={this.state.openTooltip}
          anchorEl={this.state.anchorTooltip}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography component="span" className={classes.breakWord}>
            Please use this ID when posting public issues
          </Typography>
        </Popover>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    projects: state.api.projects,
  };
}
export default withStyles(styles)(connect(mapStateToProps)(TableProjects));