export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE';

export const CHECK_USER_SUCCESS = 'CHECK_USER_SUCCESS';
export const CHECK_USER_FAILURE = 'CHECK_USER_FAILURE';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';

export const FORGOT_PWD_REQUEST = 'FORGOT_PWD_REQUEST';
export const FORGOT_PWD_SUCCESS = 'FORGOT_PWD_SUCCESS';
export const FORGOT_PWD_ERROR = 'FORGOT_PWD_ERROR';

export const UPDATE_PWD_REQUEST = 'UPDATE_PWD_REQUEST';
export const UPDATE_PWD_SUCCESS = 'UPDATE_PWD_SUCCESS';
export const UPDATE_PWD_ERROR = 'UPDATE_PWD_ERROR';

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';

export const ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_ERROR = 'ACCEPT_TERMS_ERROR';

export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR';

export const CREATE_PROJECTS_REQUEST = 'CREATE_PROJECTS_REQUEST';
export const CREATE_PROJECTS_SUCCESS = 'CREATE_PROJECTS_SUCCESS';
export const CREATE_PROJECTS_ERROR = 'CREATE_PROJECTS_ERROR';

export const DELETE_PROJECTS_REQUEST = 'DELETE_PROJECTS_REQUEST';
export const DELETE_PROJECTS_SUCCESS = 'DELETE_PROJECTS_SUCCESS';
export const DELETE_PROJECTS_ERROR = 'DELETE_PROJECTS_ERROR';

/** NOTIFICATION_MESSAGE */
export const setNotification = (notification) => {
  return {
    type: NOTIFICATION_MESSAGE,
    notification,
  };
};

/** CHECK USER SESSION */
export const checkUserSuccess = () => {
  return {
    type: CHECK_USER_SUCCESS,
  };
};

export const checkUserFailure = () => {
  return {
    type: CHECK_USER_FAILURE,
  };
};

/** SIGNUP USER */
export const signupRequest = () => {
  return {
    type: SIGNUP_REQUEST,
  };
};

export const signupSuccess = success => {
  return {
    type: SIGNUP_SUCCESS,
    success,
  };
};

export const signupError = (error) => {
  return {
    type: SIGNUP_ERROR,
    error,
  };
};

/** FORGOT PASSWORD */
export const forgotPwdRequest = () => {
  return {
    type: FORGOT_PWD_REQUEST,
  };
};

export const forgotPwdSuccess = () => {
  return {
    type: FORGOT_PWD_SUCCESS,
  };
};

export const forgotPwdError = (error) => {
  return {
    type: FORGOT_PWD_ERROR,
    error,
  };
};

/** UPDATE PASSWORD */
export const updatePwdRequest = () => {
  return {
    type: UPDATE_PWD_REQUEST,
  };
};

export const updatePwdSuccess = () => {
  return {
    type: UPDATE_PWD_SUCCESS,
  };
};

export const updatePwdError = (error) => {
  return {
    type: UPDATE_PWD_ERROR,
    error,
  };
};

/** VERIFY EMAIL */
export const verifyEmailRequest = () => {
  return {
    type: VERIFY_EMAIL_REQUEST,
  };
};

export const verifyEmailSuccess = () => {
  return {
    type: VERIFY_EMAIL_SUCCESS,
  };
};

export const verifyEmailError = (error) => {
  return {
    type: VERIFY_EMAIL_ERROR,
    error,
  };
};

/** SIGNIN USER */
export const signinRequest = () => {
  return {
    type: SIGNIN_REQUEST,
  };
};

export const signinSuccess = user => {
  return {
    type: SIGNIN_SUCCESS,
    user,
  };
};

export const signinError = (error) => {
  return {
    type: SIGNIN_ERROR,
    error,
  };
};

/** SIGNOUT USER */
export const signoutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

/** SIGNIN USER */
export const acceptTermsRequest = () => {
  return {
    type: ACCEPT_TERMS_REQUEST,
  };
};

export const acceptTermsSuccess = () => {
  return {
    type: ACCEPT_TERMS_SUCCESS,
  };
};

export const acceptTermsError = (error) => {
  return {
    type: ACCEPT_TERMS_ERROR,
    error,
  };
};

/** DELETE USER */
export const deleteUserRequest = () => {
  return {
    type: DELETE_USER_REQUEST,
  };
};

export const deleteUserSuccess = () => {
  return {
    type: DELETE_USER_SUCCESS,
  };
};

export const deleteUserError = (error) => {
  return {
    type: DELETE_USER_ERROR,
    error,
  };
};

/** GET USER */
export const getUserRequest = () => {
  return {
    type: GET_USER_REQUEST,
  };
};

export const getUserSuccess = (user) => {
  return {
    type: GET_USER_SUCCESS,
    user,
  };
};

export const getUserError = (error) => {
  return {
    type: GET_USER_ERROR,
    error,
  };
};

/** GET PROJECTS */
export const getProjectsRequest = () => {
  return {
    type: GET_PROJECTS_REQUEST,
  };
};

export const getProjectsSuccess = (user) => {
  return {
    type: GET_PROJECTS_SUCCESS,
    user,
  };
};

export const getProjectsError = (error) => {
  return {
    type: GET_PROJECTS_ERROR,
    error,
  };
};

/** CREATE PROJECTS */
export const createProjectsRequest = () => {
  return {
    type: CREATE_PROJECTS_REQUEST,
  };
};

export const createProjectsSuccess = (project) => {
  return {
    type: CREATE_PROJECTS_SUCCESS,
    project,
  };
};

export const createProjectsError = (error) => {
  return {
    type: CREATE_PROJECTS_ERROR,
    error,
  };
};

/** DELETE PROJECT */
export const deleteProjectRequest = () => {
  return {
    type: DELETE_PROJECTS_REQUEST,
  };
};

export const deleteProjectSuccess = (project) => {
  return {
    type: DELETE_PROJECTS_SUCCESS,
    project,
  };
};

export const deleteProjectError = (error) => {
  return {
    type: DELETE_PROJECTS_ERROR,
    error,
  };
};








