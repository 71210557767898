import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EmailIcon from '@material-ui/icons/Email';
import Link from '@material-ui/core/Link';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { deleteUser } from '../apis';

const styles = (theme) => ({
  paper: {
    position: 'relative',
    height: '100%',
  },
  actionButton: {
    position: 'absolute',
    bottom: 20,
    right: 20,
  },
  title: {
    borderBottom: '1px solid #000',
  },
  breakWord: {
    overflowWrap: 'break-word',
  },
  copyButton: {
    padding: 4,
    marginLeft: 4,
  },
  userId: {
    overflowWrap: 'break-word',
    display: 'inline-block',
    padding: '6px 10px',
    border: '1px solid #333',
    borderRadius: 6,
  },
  textarea: {
    width: '100%',
    fontSize: 16,
    border: '1px solid #ccc',
    resize: 'none',
    color: '#333',
    fontFamily: 'Roboto',
  },
  dialogTitle: {
    padding: 8,
  },
  dialogContent: {
    padding: 8,
  },
});

class UserDetails extends Component {
  
  state = { 
    deleteUserOpen: false,
    inviteFriendsOpen: false,
    formData: {
      password: '',
    },
    invitationMessage: '',
    invitationMessagetoCopy: '',
  }

  handleChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleClose = () => {
    this.setState({ 
      deleteUserOpen: false,
      inviteFriendsOpen: false,
    });
  }

  openDeleteUserDialog = () => {
    this.setState({ deleteUserOpen: true, formData: { password: '' } });
  }

  deleteAccount = () => {
    const { user, dispatch } = this.props;
    const { formData } = this.state;
    dispatch(deleteUser(user.email, formData.password, () => {
      this.props.history.push('/signin');
    }));
  }

  render() {
    const { classes, user } = this.props;
    const { formData } = this.state;

    let trialDays = 30;
    if (user.createdOn) {
      const remainingTime = Date.now() - user.createdOn;
      trialDays = trialDays - Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    }

    return (
      <Fragment>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography component='h2' variant="h4" className={classes.title}>
              User Details
              <Button
                style={{ float: 'right' }}
                color='primary'
                onClick={this.openDeleteUserDialog}
              >
                delete
              </Button>
            </Typography>
            {user && (
              <Box mt={1}>
                <Typography component="p" className={classes.breakWord}>
                  <strong>Email</strong><br/>
                  {user.email}
                </Typography>
              </Box>
            )}
            {user.createdOn && (
              <Box mt={2}>
                <Typography component="p">
                  <strong>Free Trial Period</strong>
                  <br/>
                  { trialDays < 0 && (
                    <span>Your 30-day trial period has expired!</span>
                  )}
                  { trialDays >= 0 && (
                    <span>Your trial period ends in <u>{trialDays} day{ trialDays > 1 && 's' }</u>.</span>
                  )}
                  <br/>
                  <Link href='https://scaledynamics.io/setting-up-demo' target='_blank'>
                    Please contact us to subscribe
                  </Link>.
                </Typography>
              </Box>
            )}
            <Box mt={2} mb={6}>
              <Typography component="p">
                <strong>Need more information?</strong><br/>
                Book a demo to discover everything you can do with WarpJS.
              </Typography>
            </Box>
            <Button
              className={classes.actionButton}
              variant='outlined'
              color='primary'
              startIcon={<EmailIcon />}
              href='https://scaledynamics.io/setting-up-demo'
              target="_blank"
            >
              Contact Us
            </Button>
          </Paper>
        </Grid>
        <Dialog
          open={this.state.deleteUserOpen}
          onClose={this.handleClose}
          aria-labelledby="Are you sure you want to delete your account?">
          <DialogTitle>Are you sure you want to delete your account?</DialogTitle>
          <ValidatorForm onSubmit={this.deleteAccount} >
            <DialogContent>
              <DialogContentText >
                Account, projects and related deployments will be detroyed. Do you wish to continue?
              </DialogContentText>
              <TextValidator
                variant="outlined"
                margin="normal"
                fullWidth
                required
                autoComplete="current-password"
                label="Password"
                name="password"
                type="password"
                validators={['required']}
                errorMessages={['Password is required']}
                onChange={this.handleChange}
                value={formData.password}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="default">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="outlined" autoFocus>
                Delete Account
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.api.user,
  };
}
export default withStyles(styles)(connect(mapStateToProps)(UserDetails));